import { getPaginationDefault } from "api/hooks"

import plural from "plural-ru"

import {
  FilterData,
  TableDataBalance,
  TableDataBetTypeValue,
  TableDataStatus
} from "./types"

import { TableConfig } from "./Config/types"
import moment from "moment"

export const classByStatus = (status: TableDataStatus) => {
  let className
  switch (status) {
    case TableDataStatus.loose:
      className = `SportboomTableStatusLoose`
      break
    case TableDataStatus.return_bet:
      className = `SportboomTableStatusReturnBet`
      break
    case TableDataStatus.win:
      className = `SportboomTableStatusWin`
      break
    case TableDataStatus.redeem:
      className = `SportboomTableStatusRedeem`
      break
    case TableDataStatus.in_game:
      className = `SportboomTableStatusInGame`
      break
  }
  return className
}

export const resultByStatus = (status: TableDataStatus, isColor: boolean) => {
  let className = ``
  switch (status) {
    case TableDataStatus.loose:
      className = isColor ? `Green700Color` : `Green700Background`
      break
    case TableDataStatus.return_bet:
      className = isColor ? `Lemon800Color` : `Lemon800Background`
      break
    case TableDataStatus.win:
      className = isColor ? `Red700Color` : `Red700Background`
      break
    case TableDataStatus.in_game:
      className = isColor ? `Gray500Color` : `Gray500Background`
      break
    case TableDataStatus.redeem:
      className = isColor ? `Violet700Color` : `Violet700Background`
      break
  }
  return className
}

export const getDateAndTime = (date?: string) => {
  if (!date) {
    return undefined
  } else {
    const returnDate =
      date?.slice(0, -9) ||
      moment(date, `YYYY-MM-DD HH:mm:ss`).format(`DD.MM.YY`)
    const returnTime =
      date?.slice(11, -3) || moment(date, `YYYY-MM-DD HH:mm:ss`).format(`HH:mm`)
    return {
      date: returnDate.slice(0, 6) + returnDate.slice(8, 10),
      time: returnTime
    }
  }
}

export enum TableColumns {
  bet_number = `bet_number`,
  sport_type = `sport_type`,
  event_id = `event_id`,
  freebet_id = `freebet_id`,
  wallet_id = `wallet_id`,
  bet_type = `bet_type`,
  bet = `bet`,
  win = `win`,
  coefficient = `coefficient`,
  result = `result`,
  status = `status`,
  guest = `guest`,
  balance = `balance`,
  hall = `hall`,
  period = `period`
}

export const defaultWidth = 109

export const tableStateInitial = {
  data: [],
  pending: false,
  total: 0
}

export const emptyTableText = {
  title: `Нет данных`,
  subtitle: `За указанный период ставки не совершались`,
  isSmall: true
}

export const emptyTableInsideText = {
  title: `Данные о событиях отсутствуют`,
  isSmall: true
}

export const tableConstants = {
  [TableColumns.bet_number]: {
    label: `№ ставки`,
    field: TableColumns.bet_number,
    width: 120
  },
  [TableColumns.sport_type]: {
    label: `Вид спорта/Event ID`,
    field: TableColumns.sport_type,
    width: 176
  },
  [TableColumns.event_id]: {
    label: `Event ID`,
    placeholder: `Укажите ID`,
    field: TableColumns.event_id,
    width: 100,
    more: (count: number) => {
      const label = plural(count, `событие`, `события`, `событий`)
      return `${count} ${label}`
    },
    inside_label: (id: string) => `Event ID ${id}`
  },
  [TableColumns.period]: {
    label: `Период`,
    field: TableColumns.period,
    width: 130
  },
  [TableColumns.bet_type]: {
    label: `Вид ставки`,
    field: TableColumns.bet_type,
    width: 135,
    types: {
      [TableDataBetTypeValue.express]: `Экспресс`,
      [TableDataBetTypeValue.ordinary]: `Ординар`,
      [TableDataBetTypeValue.system]: `Система`
    }
  },
  [TableColumns.bet]: {
    label: `Ставка, ₽`,
    field: TableColumns.bet,
    width: defaultWidth
  },
  [TableColumns.win]: {
    label: `Выигрыш, ₽`,
    field: TableColumns.win,
    width: 120
  },
  [TableColumns.coefficient]: {
    label: `Коэффициент `,
    shortLabel: `Коэф.`,
    field: TableColumns.coefficient,
    width: 80
  },
  [TableColumns.result]: {
    label: `Результат (WIN)`,
    field: TableColumns.result,
    width: 142
  },
  [TableColumns.status]: {
    label: `Статус`,
    field: TableColumns.status,
    width: 148,
    text: {
      [TableDataStatus.loose]: `Проигрыш`,
      [TableDataStatus.win]: `Выигрыш`,
      [TableDataStatus.redeem]: `Выкуп`,
      [TableDataStatus.return_bet]: `Возврат`,
      [TableDataStatus.in_game]: `В игре`
    }
  },
  [TableColumns.guest]: {
    label: `Гость`,
    field: TableColumns.guest,
    placeholder: `Укажите как зовут гостя`,
    width: 130
  },
  [TableColumns.balance]: {
    label: `Баланс`,
    field: TableColumns.balance,
    width: 104,
    text: {
      [TableDataBalance.money]: `Денежный`,
      [TableDataBalance.freebet]: `Фрибеты`
    }
  },
  [TableColumns.freebet_id]: {
    label: `Freebet ID`,
    field: TableColumns.freebet_id,
    width: 104
  },
  [TableColumns.wallet_id]: {
    label: `№ кошелька`,
    field: TableColumns.wallet_id,
    width: defaultWidth
  },
  [TableColumns.hall]: {
    label: `Клуб`,
    field: TableColumns.hall,
    width: 70
  }
}

export const initialFilters: FilterData = {
  sort: {
    field: undefined
  },
  pagination: getPaginationDefault(),
  table: {
    [TableColumns.win]: undefined,
    [TableColumns.coefficient]: undefined,
    [TableColumns.balance]: undefined,
    [TableColumns.status]: undefined,
    [TableColumns.sport_type]: undefined,
    [TableColumns.guest]: undefined,
    [TableColumns.bet]: undefined,
    [TableColumns.bet_type]: undefined,
    [TableColumns.result]: undefined,
    [TableColumns.event_id]: undefined,
    [TableColumns.period]: undefined
  }
}

export const initialColumnList: TableConfig = {
  open: false,
  hideCount: 0,
  template: null,
  data: [
    {
      field: TableColumns.bet_number,
      visible: true
    },
    {
      field: TableColumns.sport_type,
      visible: true
    },
    {
      field: TableColumns.period,
      visible: true
    },
    {
      field: TableColumns.bet_type,
      visible: true
    },
    {
      field: TableColumns.bet,
      visible: true
    },
    {
      field: TableColumns.win,
      visible: true
    },
    {
      field: TableColumns.coefficient,
      visible: true
    },
    {
      field: TableColumns.result,
      visible: true
    },
    {
      field: TableColumns.status,
      visible: true
    },
    {
      field: TableColumns.guest,
      visible: true
    },
    {
      field: TableColumns.balance,
      visible: true
    },
    {
      field: TableColumns.freebet_id,
      visible: true
    },
    {
      field: TableColumns.wallet_id,
      visible: true
    },
    {
      field: TableColumns.hall,
      visible: true
    }
  ]
}

export const filterLists: {
  [key: string]: {
    filter: {
      label: string
      value: any
    }[]
    preview: any[]
  }
} = {
  [TableColumns.bet_type]: {
    filter: [
      {
        label:
          tableConstants[TableColumns.bet_type].types[
            TableDataBetTypeValue.express
          ],
        value: TableDataBetTypeValue.express
      },
      {
        label:
          tableConstants[TableColumns.bet_type].types[
            TableDataBetTypeValue.ordinary
          ],
        value: TableDataBetTypeValue.ordinary
      },
      {
        label:
          tableConstants[TableColumns.bet_type].types[
            TableDataBetTypeValue.system
          ],
        value: TableDataBetTypeValue.system
      }
    ],
    preview: [
      TableDataBetTypeValue.express,
      TableDataBetTypeValue.ordinary,
      TableDataBetTypeValue.system
    ]
  },
  [TableColumns.balance]: {
    filter: [
      {
        value: TableDataBalance.money,
        label: `Денежный`
      },
      {
        value: TableDataBalance.freebet,
        label: `Фрибеты`
      }
    ],
    preview: [TableDataBalance.money, TableDataBalance.freebet]
  },
  [TableColumns.status]: {
    filter: [
      {
        value: TableDataStatus.in_game,
        label: tableConstants[TableColumns.status].text[TableDataStatus.in_game]
      },
      {
        value: TableDataStatus.win,
        label: tableConstants[TableColumns.status].text[TableDataStatus.win]
      },
      {
        value: TableDataStatus.redeem,
        label: tableConstants[TableColumns.status].text[TableDataStatus.redeem]
      },
      {
        value: TableDataStatus.loose,
        label: tableConstants[TableColumns.status].text[TableDataStatus.loose]
      },
      {
        value: TableDataStatus.return_bet,
        label:
          tableConstants[TableColumns.status].text[TableDataStatus.return_bet]
      }
    ],
    preview: [
      TableDataStatus.win,
      TableDataStatus.redeem,
      TableDataStatus.in_game,
      TableDataStatus.loose,
      TableDataStatus.return_bet
    ]
  }
}
