import React from "react"

import plural from "plural-ru"

import { TableCardItemLength } from "Components/TableNew/TableCards/TableCard/types"

import {
  classByStatus,
  resultByStatus,
  TableColumns,
  tableConstants
} from "../constants"
import { blankValue, WalletIdCell } from "../helper"
import {
  TableData,
  TableDataBalance,
  TableDataBetTypeValue,
  TableDataStatus
} from "../types"

export const detailInitial = { open: false, list: [], dataList: {} }

export const detailButtons = {
  cancel_check: `Снять выбор`,
  open_detail: (count: number) => {
    const word = plural(count, `ставку`, `ставки`, `ставок`)
    return `Сравнить ${count} ${word}`
  }
}

export const betNumberText = (bet: number) => `Ставка № ${bet}`

export const detailConfigByData = (data: TableData) => [
  {
    value: data.guest,
    label: `Гость`
  },
  {
    value: data.bet_start,
    label: `Дата и время начала ставки`
  },
  {
    value: tableConstants.bet_type.types[data.bet_type.value],
    label: `Тип`
  },
  {
    value: data.bet,
    label: `Ставка`
  },
  {
    value: data.win,
    label: `Выигрыш`
  }
]

export const tableDetailConstants = {
  deleteItem: `Удалить из сравнения`,
  sport_type: {
    label: `Вид спорта`,
    field: `sport_type`
  },
  bet_option: {
    label: `Вид ставки`,
    field: `bet_option`
  },
  market: {
    label: `Маркет`,
    field: `market`
  },
  event: {
    label: `Событие`,
    field: `event`
  },
  tournament: {
    label: `Название турнира`,
    field: `tournament`
  },
  coefficient: {
    label: `Коэф.`,
    field: `coefficient`
  },
  check: {
    label: `Счет на момент\nзаключения пари`,
    field: `check`
  },
  status: {
    label: `Статус`,
    field: `status`
  }
}

export const detailColumns = [
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing TableHeaderFirstTh">
        {tableDetailConstants.sport_type.label}
      </div>
    ),
    dataIndex: tableDetailConstants.sport_type.field,
    key: tableDetailConstants.sport_type.field,
    // fixed: true,
    render: (sport: number) => (
      <span className="TextDefaultBold TableHeaderFirstTh">{sport}</span>
    )
  },
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing TableHeaderFirstTh">
        {tableDetailConstants.bet_option.label}
      </div>
    ),
    dataIndex: tableDetailConstants.bet_option.field,
    key: tableDetailConstants.bet_option.field,
    render: (bet_option: TableDataBetTypeValue) => (
      <span className="TableHeaderFirstTh">
        {tableConstants[TableColumns.bet_type].types[bet_option]}
      </span>
    )
  },
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing TableHeaderFirstTh">
        {tableDetailConstants.market.label}
      </div>
    ),
    dataIndex: tableDetailConstants.market.field,
    key: tableDetailConstants.market.field,
    render: (market: string) => (
      <span className="TableHeaderFirstTh">{market}</span>
    )
  },
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing TableHeaderFirstTh">
        {tableDetailConstants.event.label}
      </div>
    ),
    dataIndex: tableDetailConstants.event.field,
    key: tableDetailConstants.event.field,
    render: (event: string) => (
      <span className="TableHeaderFirstTh">{event}</span>
    )
  },
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing TableHeaderFirstTh">
        {tableDetailConstants.tournament.label}
      </div>
    ),
    dataIndex: tableDetailConstants.tournament.field,
    key: tableDetailConstants.tournament.field,
    render: (tournament: string) => (
      <span className="TableHeaderFirstTh">{tournament}</span>
    )
  },
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing TableHeaderFirstTh">
        {tableDetailConstants.coefficient.label}
      </div>
    ),
    dataIndex: tableDetailConstants.coefficient.field,
    key: tableDetailConstants.coefficient.field,
    render: (coefficient: string) => (
      <span className="TableHeaderFirstTh">{coefficient}</span>
    )
  },
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing TableHeaderFirstTh">
        {tableDetailConstants.check.label}
      </div>
    ),
    dataIndex: tableDetailConstants.check.field,
    key: tableDetailConstants.check.field,
    render: (check: string) => (
      <span className="TableHeaderFirstTh">{check}</span>
    )
  },
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing TableHeaderFirstTh">
        {tableDetailConstants.status.label}
      </div>
    ),
    width: 200,
    dataIndex: tableDetailConstants.status.field,
    key: tableDetailConstants.status.field,
    render: (status: TableDataStatus | null) => {
      const className = !!status ? classByStatus(status) : ``
      return (
        <div className={className}>
          {!!status
            ? tableConstants[TableColumns.status].text[status]
            : blankValue}
        </div>
      )
    }
  }
]

export const createCommonCardItems = (bet: TableData) => {
  const statusColor = resultByStatus(bet.status, true)
  const statusBackground = classByStatus(bet.status)
  return [
    {
      description: tableConstants[TableColumns.status].label,
      text: (
        <div className={`StatusText ${statusColor} ${statusBackground}`}>
          {tableConstants[TableColumns.status].text[bet.status]}
        </div>
      )
    },
    {
      description: tableConstants[TableColumns.bet_type].label,
      text:
        tableConstants[TableColumns.bet_type].types[bet.bet_type.value] || `-`
    },
    {
      description: tableConstants[TableColumns.event_id].label,
      text: bet.details[0]?.event_id ? bet.details[0]?.event_id : `-`
    },
    {
      description: `Окончание`,
      text: bet.bet_end
    },
    {
      description: tableConstants[TableColumns.bet].label,
      text: bet.bet
    },
    {
      description: tableConstants[TableColumns.win].label,
      text: bet.win
    },
    {
      description: tableConstants[TableColumns.result].label,
      text: bet.result,
      className: statusColor
    },
    {
      description: tableConstants[TableColumns.coefficient].label,
      text: bet.coefficient || `-`
    }
  ]
}

export const createGuestCardItems = (
  bet: TableData,
  onWalletIdClick: (walletId: number) => void
) => {
  return [
    {
      description: tableConstants[TableColumns.guest].label,
      text: bet.guest,
      cardLength: TableCardItemLength.single
    },
    {
      description: tableConstants[TableColumns.balance].label,
      text:
        tableConstants[TableColumns.balance].text[
          bet.balance as TableDataBalance
        ]
    },
    {
      description: tableConstants[TableColumns.freebet_id].label,
      text: bet.freebet_id ? bet.freebet_id : `-`
    },
    {
      description: tableConstants[TableColumns.wallet_id].label,
      text: (
        <WalletIdCell id={bet.wallet_id} onWalletIdClick={onWalletIdClick} />
      )
    },
    {
      description: tableConstants[TableColumns.hall].label,
      text: bet.hall
    }
  ]
}

export const createCollapsibleConfig = (bet: TableData) => {
  return bet.details.map(gameDetail => {
    const statusMarker = resultByStatus(gameDetail.status, false)
    return {
      titlePrefix: (
        <div
          className={`${statusMarker} SportboomTableMobileItemDetailStatus`}
        />
      ),
      title: gameDetail.sport_type,
      items: [
        {
          description: tableConstants[TableColumns.event_id].label,
          text: gameDetail.event_id ? gameDetail.event_id : `-`,
          cardLength: TableCardItemLength.single
        },
        {
          description: tableConstants[TableColumns.bet_type].label,
          text:
            tableConstants[TableColumns.bet_type].types[gameDetail.bet_option]
        },
        {
          description: tableConstants[TableColumns.coefficient].label,
          text: gameDetail.coefficient
        },
        {
          description: `Маркет`,
          text: gameDetail.market,
          cardLength: TableCardItemLength.single
        },
        {
          description: `Событие`,
          text: gameDetail.event,
          cardLength: TableCardItemLength.single
        },
        {
          description: `Название турнира`,
          text: gameDetail.tournament,
          cardLength: TableCardItemLength.single
        },
        {
          description: `Счет на момент заключения пари`,
          text: gameDetail.check,
          cardLength: TableCardItemLength.single
        }
      ]
    }
  })
}
