import React, { useEffect, useMemo, useState } from "react"

import { useRequest, UseRequestBodyPagination } from "api/hooks"

import { isEqual } from "lodash"

import NewTableComponent from "Components/TableNew"
import { EmptyData, EmptyTableLine } from "Components/EmptyTable"

import { ReactComponent as InfoIcon } from "Components/icons/info.svg"

import { SportBoomContentTableProps } from "../Chart/types"

import { parseDates, sportboomMainText, SportBoomTabs } from "../../constants"

import CompareBets from "./Detail/Compare"
import { detailInitial } from "./Detail/constants"

import { getColumns, getInsideColumns, insideCheckColumn } from "./helper"
import { DetailModalProps, TableData, TableDataState } from "./types"
import {
  emptyTableInsideText,
  emptyTableText,
  initialFilters,
  TableColumns,
  tableStateInitial
} from "./constants"

const SportboomTable = ({
  activeTab,
  filter,
  tableConfig,
  clearConfig,
  gameList,
  tableFilter,
  setTableFilter,
  toggleActiveTab,
  onWalletIdClick
}: SportBoomContentTableProps) => {
  const onlyBetNumber = !!filter.bet_number

  const [detailOpen, toggleDetailOpen] = useState<DetailModalProps>(
    detailInitial
  )
  const [betsList, setBetsList] = useState<number[]>([])
  const changeDetailDataList = (bet_number: number, record: TableData) => {
    toggleDetailOpen({
      ...detailOpen,
      dataList: {
        ...detailOpen.dataList,
        [bet_number]: record
      }
    })
  }
  const removeListItem = (removeItem: number) => {
    const newList = betsList.filter(listItem => listItem !== removeItem)
    setBetsList(newList)
  }
  const openDetailCurrent = (bet_number: number) => {
    const currentData = tableData.data.find(
      item => item.bet_number === bet_number
    )

    if (!!currentData) {
      toggleDetailOpen({
        ...detailOpen,
        open: true,
        current: bet_number,
        currentData: currentData
      })
    }
  }

  const setPagination = (pag: UseRequestBodyPagination) =>
    setTableFilter({ ...tableFilter, pagination: pag })

  const [tableData, setTableData] = useState<TableDataState>(tableStateInitial)

  const depsObj = {
    ...tableData.data,
    ...tableConfig,
    ...betsList,
    ...tableFilter
  }
  const columnDeps = useMemo(() => depsObj, [depsObj])

  const { list: columns, widthCount } = useMemo(() => {
    return getColumns({
      selectedList: betsList,
      openDetailCurrent,
      tableConfig,
      tableFilter,
      setTableFilter,
      gameList: gameList.data,
      onWalletIdClick
    })
  }, [columnDeps])

  const { list: insideColumns } = useMemo(
    () =>
      getInsideColumns({
        selectedList: betsList,
        tableConfig
      }),
    [tableData.data, tableConfig, betsList]
  )

  const { request: getTableData } = useRequest({
    url: `v1/sportboom/table`,
    requestBody: {
      filters: {
        ...filter,
        ...tableFilter.table,
        pagination: undefined,
        dates: parseDates(filter)
      },
      sort: !!tableFilter?.sort.field ? tableFilter.sort : undefined,
      type: activeTab,
      pagination: tableFilter.pagination
    }
  })

  useEffect(() => {
    setBetsList([])
    clearConfig()
  }, [activeTab])

  const requestTable = () => {
    const filterReq =
      !!filter.dates || !!filter.bet_number || !!filter.wallet_number
    if (filterReq) {
      if (!!filter.wallet_number && activeTab !== SportBoomTabs.sport_bets) {
        toggleActiveTab(SportBoomTabs.sport_bets)
      } else {
        setTableData({ ...tableData, pending: true })
        setBetsList([])
        toggleDetailOpen(detailInitial)
        getTableData()
          .then(({ data, headers }) => {
            const dataWithKey = data.map((item: TableData) => ({
              ...item,
              key: item.bet_number
            }))
            setTableData({
              data: dataWithKey,
              pending: false,
              total: headers[`total-count`]
            })
          })
          .catch(() => setTableData({ ...tableData, pending: false }))
      }
    }
  }

  const [canRequest, toggleReq] = useState(false)

  const paginationDeps = JSON.stringify({ ...filter })

  useEffect(() => {
    if (!!filter.bet_number) {
      const isSportBets = activeTab === SportBoomTabs.sport_bets
      const isInitialFilter = isEqual(tableFilter, initialFilters)
      if (isSportBets && isInitialFilter && canRequest) {
        requestTable()
      } else if (!isInitialFilter) {
        setTableFilter(initialFilters)
      } else {
        toggleActiveTab(SportBoomTabs.sport_bets)
      }
    } else if (tableFilter.pagination.offset === 0) {
      requestTable()
    } else {
      setTableFilter({
        ...tableFilter,
        pagination: { ...tableFilter.pagination, offset: 0 }
      })
    }
  }, [paginationDeps])

  const requestDeps = JSON.stringify({
    activeTab,
    ...tableFilter
  })

  useEffect(() => {
    if (!!filter.bet_number) {
      setTableFilter(initialFilters)
      toggleReq(true)
      toggleActiveTab(SportBoomTabs.sport_bets)
      if (
        activeTab === SportBoomTabs.sport_bets &&
        tableFilter.pagination.offset === 0
      ) {
        requestTable()
      }
    } else if (canRequest) {
      requestTable()
    } else {
      toggleReq(true)
    }
  }, [requestDeps])

  const isInsideEmpty = useMemo(() => {
    const insideFields = [
      TableColumns.sport_type,
      TableColumns.bet_type,
      TableColumns.coefficient,
      TableColumns.status
    ]
    const checkArray = tableConfig.filter(
      item => insideFields.includes(item.field) && item.visible
    )
    return !checkArray.length
  }, [tableConfig])

  const rowSelection = {
    onChange: (selectedRowKeys: number[]) => {
      const selectedLength = selectedRowKeys.length
      if (selectedLength === tableFilter.pagination.limit) {
        const dataList: any = {}
        const detailData = tableData.data.map(item => {
          dataList[item.bet_number] = item
        })
        toggleDetailOpen({ ...detailOpen, dataList })
      } else if (betsList.length > selectedLength) {
        // delete item
        const deleteItem = betsList.filter(
          bet => !selectedRowKeys.includes(bet)
        )[0]
        if (!!deleteItem) {
          const dataListCopy = detailOpen.dataList
          delete dataListCopy[deleteItem]
          toggleDetailOpen({ ...detailOpen, dataList: dataListCopy })
        }
      } else {
        // add items
        const addItems: any = {}
        const currentDetailList = tableData.data.filter(tableItem => {
          if (selectedRowKeys.includes(tableItem.bet_number)) {
            addItems[tableItem.bet_number] = tableItem
            return true
          } else {
            return false
          }
        })
        if (!!currentDetailList.length) {
          toggleDetailOpen({ ...detailOpen, dataList: addItems })
        }
      }
      setBetsList(selectedRowKeys)
    },
    selectedRowKeys: betsList
  }
  return (
    <>
      <NewTableComponent
        data={tableData.data}
        loading={tableData.pending}
        columns={columns}
        width={widthCount}
        tableClassName="SportboomTable"
        rowClassName="ExpandableTableRow"
        rowClassNameByRecord={record =>
          !!record?.bet_number && betsList.includes(record?.bet_number)
            ? `SportboomTableActiveRow ant-table-cell-fix-left`
            : `SportboomTableRow ant-table-cell-fix-left`
        }
        rowSelection={
          !onlyBetNumber && {
            type: `checkbox`,
            ...rowSelection
          }
        }
        locale={{
          emptyText: (
            <div>
              <EmptyData {...emptyTableText} pending={tableData.pending} />
            </div>
          )
        }}
        expandable={{
          expandRowByClick: true,
          expandIconColumnIndex: -1,
          onExpand: (expanded: boolean, record: TableData) => {
            if (!betsList.includes(record.bet_number) && expanded) {
              const newList = [...betsList, record.bet_number]
              changeDetailDataList(record.bet_number, record)
              toggleDetailOpen({
                ...detailOpen,
                dataList: {
                  ...detailOpen.dataList,
                  [record.bet_number]: record
                }
              })
              setBetsList(newList)
            }
          },
          defaultExpandAllRows: true,
          expandedRowRender: (record: TableData) => {
            if (isInsideEmpty) {
              return (
                <div className="SportboomTableInsideEmpty">
                  <div className="FlexRow">
                    <InfoIcon />
                    <span className="TextDefault Gray600Color">
                      {sportboomMainText.tableConfig.emptyInside}
                    </span>
                  </div>
                </div>
              )
            }
            const isActive = betsList.includes(record.bet_number)
            const dataBySportTypes = record.sport_type.map(item => ({
              ...record,
              sport_type: item.name,
              event_id: item.event_id,
              status: item.status,
              bet_type: item.bet_type_description,
              coefficient: item.coefficient,
              key: item
            }))
            return (
              <NewTableComponent
                data={dataBySportTypes}
                width={widthCount}
                columns={
                  onlyBetNumber
                    ? insideColumns
                    : [insideCheckColumn, ...insideColumns]
                }
                showHeader={false}
                rowClassName={`SportboomInsideTableRow ${
                  isActive && !onlyBetNumber
                    ? `SportboomInsideTableActiveRow`
                    : ``
                }`}
                locale={{
                  emptyText: (
                    <div>
                      <EmptyTableLine
                        description={emptyTableInsideText.title}
                      />
                    </div>
                  )
                }}
                tableClassName="SportboomInsideTableWrapper"
              />
            )
          }
        }}
        pagination={{
          value: tableFilter.pagination,
          total: tableData.total,
          onChange: setPagination
        }}
      />
      <CompareBets
        onWalletIdClick={onWalletIdClick}
        betsList={betsList}
        removeListItem={removeListItem}
        openDetail={detailOpen.open}
        detailOpen={detailOpen}
        clear={() => setBetsList([])}
        closeModal={() =>
          toggleDetailOpen({
            ...detailOpen,
            open: false,
            current: undefined,
            currentData: undefined
          })
        }
        openDetailModal={() =>
          toggleDetailOpen({ ...detailOpen, list: betsList, open: true })
        }
      />
    </>
  )
}

export default SportboomTable
