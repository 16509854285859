import React from "react"

import { getPaginationDefault } from "api/hooks"

import { isEqual } from "lodash"

import { formatToCurrency, roleConstants } from "constants/index"

import { ReactComponent as LiveIcon } from "Containers/SportBoom/img/live.svg"
import { ReactComponent as RubleIcon } from "Components/icons/money_circle_outline.svg"
import { ReactComponent as FreebetIcon } from "Components/icons/percent_circle_outline_24.svg"
import { ReactComponent as WalletIcon } from "Components/icons/wallet.svg"

import {
  CheckColumnTitle,
  ColumnsOptionsProps,
  FilterData,
  FromToColumnProps,
  GetColumnsMethodProps,
  GetInsideColumnsMethodProps,
  InsideColumnsOptionsMethodProps,
  SportTypeData,
  TableData,
  TableDataBalance,
  TableDataBetType,
  TableDataStatus
} from "./types"

import { TableHeader } from "Components/TableNew"

import { getColumnSort } from "Components/TableNew/helpers"

import { FromToFilter } from "Components/TableNew/TableFilters/FromToFilter"
import { FromToFilterSetterProps } from "Components/TableNew/TableFilters/FromToFilter/types"
import CheckListFilter from "Components/TableNew/TableFilters/CheckListFilter"
import FromToDateFilter, {
  getInitialByFilter
} from "Components/TableNew/TableFilters/FromToDates"
import SearchFilter from "Components/TableNew/TableFilters/SearchFilter"

import {
  classByStatus,
  filterLists,
  getDateAndTime,
  initialFilters,
  resultByStatus,
  TableColumns,
  tableConstants
} from "./constants"
import { WalletIdCellProps } from "../WalletModal/types"

const emptyField = (width: number) => <div style={{ width: width - 24 }} />
export const blankValue = `-`

export const insideCheckColumn = {
  title: () => <div className="TableHeaderWithoutSpacing TableHeaderFirstTh" />,
  width: 32,
  dataIndex: `checkBlank`,
  key: `checkBlank`,
  render: () => emptyField(32)
}

export const checkIsFirstRequest = (tableFilter: FilterData) =>
  isEqual(tableFilter, initialFilters)

export const WalletIdCell: React.FC<WalletIdCellProps> = ({
  id,
  onWalletIdClick,
  additionalClassName
}) => {
  const { isSportBoomWalletFeature } = roleConstants()

  if (!isSportBoomWalletFeature) {
    return <div>{id}</div>
  }

  return !!id ? (
    <span
      className={`SportboomWalletCellWrapper ${additionalClassName}`}
      onClick={e => {
        e.stopPropagation()
        onWalletIdClick && onWalletIdClick(id)
      }}
    >
      <WalletIcon />
      <div>{id}</div>
    </span>
  ) : (
    <></>
  )
}

export const FromToColumn = ({
  tableFilter,
  checkIsFirstClass,
  setTableFilter,
  checkIsFirstFix,
  fromToField
}: FromToColumnProps) => {
  return {
    title: () => {
      const field = tableFilter.table[fromToField]
      const isActive =
        (!!field &&
          (!!field.to || field.to === 0 || !!field.from || field.from === 0)) ||
        tableFilter.sort.field === fromToField

      const isSort =
        tableFilter.sort &&
        !!tableFilter.sort.order &&
        tableFilter.sort.field === fromToField
          ? getColumnSort(tableFilter.sort.order)
          : undefined
      const shortTitleForCoefficient =
        fromToField === TableColumns.coefficient
          ? tableConstants[fromToField].shortLabel
          : tableConstants[fromToField].label
      return (
        <TableHeader
          isActiveField={isActive}
          isSort={isSort}
          className={checkIsFirstClass(fromToField)}
          title={shortTitleForCoefficient}
        >
          <FromToFilter
            suffix={
              fromToField === TableColumns.coefficient ? (
                <FreebetIcon />
              ) : (
                <RubleIcon />
              )
            }
            isFloatNumber={fromToField === TableColumns.coefficient}
            fieldName={fromToField}
            title={tableConstants[fromToField].label}
            from={tableFilter.table[fromToField]?.from}
            to={tableFilter.table[fromToField]?.to}
            currentSort={tableFilter.sort}
            defaultSort={tableFilter.sort}
            defaultFilter={{
              field: fromToField,
              from: initialFilters.table[fromToField]?.from,
              to: initialFilters.table[fromToField]?.to
            }}
            currentFilter={{
              ...tableFilter.table[fromToField],
              field: fromToField
            }}
            setter={(searchState: FromToFilterSetterProps) => {
              const newSort =
                !!searchState.sortField ||
                tableFilter.sort.field === fromToField
                  ? {
                      field: searchState.sortField,
                      order: searchState.order
                    }
                  : tableFilter.sort
              setTableFilter({
                ...tableFilter,
                sort: newSort,
                pagination: getPaginationDefault(tableFilter.pagination.limit),
                table: {
                  ...tableFilter.table,
                  [fromToField]:
                    searchState.from ||
                    searchState.to ||
                    searchState.from === 0 ||
                    searchState.to === 0
                      ? {
                          from: searchState.from,
                          to: searchState.to
                        }
                      : undefined
                }
              })
            }}
          />
        </TableHeader>
      )
    },
    fixed: checkIsFirstFix(fromToField),
    width: tableConstants[fromToField].width,
    dataIndex: fromToField,
    key: fromToField,
    render: (fromTo: number, record: TableData) => {
      const classNameForResult =
        fromToField === TableColumns.result
          ? `TextDefaultBold ${resultByStatus(record.status, true)}`
          : ``
      const numberFromTo = Number(fromTo)
      const fromToResult =
        !!numberFromTo ||
        (numberFromTo === 0 && fromToField !== TableColumns.coefficient)
          ? formatToCurrency({
              amount: numberFromTo,
              withoutSpace: true
            })
          : blankValue
      return (
        <span
          className={`${checkIsFirstClass(fromToField)} ${classNameForResult}`}
        >
          {fromToResult}
        </span>
      )
    }
  }
}

export const CheckListFilterTitle = ({
  tableFilter,
  setTableFilter,
  checkIsFirstClass,
  checkField
}: CheckColumnTitle) => {
  const isActive = !!tableFilter && !!tableFilter.sort && !!setTableFilter
  const isSort =
    tableFilter.sort &&
    !!tableFilter.sort.order &&
    tableFilter.sort.field === checkField
      ? getColumnSort(tableFilter.sort.order)
      : undefined
  return isActive ? (
    <TableHeader
      isActiveField={
        tableFilter.sort.field === TableColumns.status ||
        !!tableFilter.table[checkField]
      }
      isSort={isSort}
      className={checkIsFirstClass(checkField)}
      title={tableConstants[checkField].label}
    >
      <CheckListFilter
        list={filterLists[checkField].filter}
        initialList={tableFilter.table[checkField]}
        changeFilter={(newList: any[]) => {
          const isAll = filterLists[checkField].filter.length === newList.length
          setTableFilter({
            ...tableFilter,
            pagination: getPaginationDefault(tableFilter.pagination.limit),
            table: {
              ...tableFilter.table,
              [checkField]: isAll ? undefined : newList
            }
          })
        }}
      />
    </TableHeader>
  ) : (
    <div className="TableHeaderWithoutSpacing TableHeaderFirstTh">
      {tableConstants[TableColumns.status].label}
    </div>
  )
}

export const columnsOptions = ({
  selectedList,
  openDetailCurrent,
  key,
  tableFilter,
  setTableFilter,
  gameList,
  firstElem,
  onWalletIdClick
}: ColumnsOptionsProps) => {
  const gameListForFilter = gameList.map(item => ({
    value: `${item.id}`,
    label: item.name
  }))
  const checkIsFirstFix = (column: TableColumns) =>
    firstElem === column ? `left` : ``
  const checkIsFirstClass = (column: TableColumns) => {
    if (firstElem === TableColumns.status) {
      return `SportboomTableFirstItemMargin`
    } else {
      return firstElem === column ? `SportboomTableFirstItem` : ``
    }
  }
  const allColumnRenders = {
    [TableColumns.bet_number]: {
      title: () => (
        <div
          className={`TableHeaderWithoutSpacing ${checkIsFirstClass(
            TableColumns.bet_number
          )}`}
        >
          {tableConstants[TableColumns.bet_number].label}
        </div>
      ),
      width: tableConstants[TableColumns.bet_number].width,
      fixed: checkIsFirstFix(TableColumns.bet_number),
      dataIndex: TableColumns.bet_number,
      key: TableColumns.bet_number,
      render: (bet_number: number) => {
        const isSelected = selectedList.includes(bet_number)
        return (
          <div
            className={`${
              isSelected ? `SportboomTableBetNumberSelected` : ``
            } FlexRow`}
          >
            <span
              className={`TextDefaultBold ${checkIsFirstClass(
                TableColumns.bet_number
              )} LinkText HoverItem`}
              onClick={e => {
                e.stopPropagation()
                openDetailCurrent(bet_number)
              }}
            >
              {bet_number}
            </span>
          </div>
        )
      }
    },
    [TableColumns.sport_type]: {
      title: () => {
        const isSort =
          tableFilter.sort &&
          !!tableFilter.sort.order &&
          tableFilter.sort.field === TableColumns.sport_type
            ? getColumnSort(tableFilter.sort.order)
            : undefined
        return !!tableFilter && !!tableFilter.sort && !!setTableFilter ? (
          <TableHeader
            isActiveField={
              tableFilter.sort.field === TableColumns.sport_type ||
              !!tableFilter.table[TableColumns.sport_type]?.length
            }
            isSort={isSort}
            className={`SportTypeTableFilterHeader ${checkIsFirstClass(
              TableColumns.sport_type
            )}`}
            title={tableConstants[TableColumns.sport_type].label}
          >
            <CheckListFilter
              withSearch={true}
              list={gameListForFilter}
              initialList={tableFilter.table[TableColumns.sport_type]}
              searchFieldConfig={{
                filed: TableColumns.event_id,
                value: tableFilter.table[TableColumns.event_id]?.value,
                placeholder: tableConstants[TableColumns.event_id].placeholder,
                title: tableConstants[TableColumns.event_id].label
              }}
              changeFilter={(newList: any[], customValue?: any) => {
                const isAll = gameListForFilter.length === newList.length
                setTableFilter({
                  ...tableFilter,
                  pagination: getPaginationDefault(
                    tableFilter.pagination.limit
                  ),
                  table: {
                    ...tableFilter.table,
                    [TableColumns.event_id]: !!customValue
                      ? {
                          value: customValue,
                          equal: true
                        }
                      : undefined,
                    [TableColumns.sport_type]: isAll ? undefined : newList
                  }
                })
              }}
            />
          </TableHeader>
        ) : (
          <div className="TableHeaderWithoutSpacing">
            {tableConstants[TableColumns.sport_type].label}
          </div>
        )
      },
      fixed: checkIsFirstFix(TableColumns.sport_type),
      width: tableConstants[TableColumns.sport_type].width,
      dataIndex: TableColumns.sport_type,
      key: TableColumns.sport_type,
      render: (sport_type: SportTypeData[]) => {
        const count = sport_type?.length || 0
        const eventId = tableConstants[TableColumns.event_id].more(count)
        return count ? (
          <div
            className={`FlexColumn Gap4 ${checkIsFirstClass(
              TableColumns.sport_type
            )}`}
          >
            <span className="TextDefaultBold">
              {sport_type ? sport_type[0]?.sport_type : ``}
            </span>
            <span className="Gray600Color">{eventId}</span>
          </div>
        ) : (
          blankValue
        )
      }
    },
    [TableColumns.period]: {
      title: () => {
        const {
          initialToTime,
          initialToDate,
          initialFromDate,
          initialFromTime
        } = getInitialByFilter(
          tableFilter ? tableFilter.table[TableColumns.period] : undefined
        )
        const isSort =
          tableFilter.sort &&
          !!tableFilter.sort.order &&
          tableFilter.sort.field === TableColumns.period
            ? getColumnSort(tableFilter.sort.order)
            : undefined
        return (
          <TableHeader
            isSort={isSort}
            isActiveField={
              tableFilter.sort.field === TableColumns.period ||
              !!tableFilter.table[TableColumns.period]?.from ||
              !!tableFilter.table[TableColumns.period]?.to
            }
            className={`${checkIsFirstClass(
              TableColumns.period
            )} PeriodTableFilterHeader`}
            title={tableConstants[TableColumns.period].label}
          >
            <FromToDateFilter
              hasSort={tableFilter.sort.field === TableColumns.period}
              order={tableFilter.sort.order}
              fromDate={initialFromDate}
              fromTime={initialFromTime}
              toDate={initialToDate}
              toTime={initialToTime}
              changeFilter={(newFilter: any) => {
                const falseSort =
                  tableFilter.sort.field === TableColumns.period
                    ? {}
                    : tableFilter.sort
                setTableFilter({
                  ...tableFilter,
                  sort: newFilter.hasSort
                    ? {
                        order: newFilter.order,
                        field: TableColumns.period
                      }
                    : falseSort,
                  pagination: getPaginationDefault(
                    tableFilter.pagination.limit
                  ),
                  table: {
                    ...tableFilter.table,
                    [TableColumns.period]:
                      newFilter.from || newFilter.to
                        ? {
                            from: newFilter.from,
                            to: newFilter.to
                          }
                        : undefined
                  }
                })
              }}
            />
          </TableHeader>
        )
      },
      fixed: checkIsFirstFix(TableColumns.period),
      width: tableConstants[TableColumns.period].width,
      dataIndex: `bet_start`,
      key: `bet_start`,
      render: (bet_start: string, record: { bet_end: string }) => {
        const parseDateStart = getDateAndTime(bet_start)
        const parseDateEnd = getDateAndTime(record.bet_end)
        return (
          <div
            className={`FlexColumn ${checkIsFirstClass(TableColumns.period)}`}
          >
            {!!parseDateStart ? (
              <div className="FlexRow SportboomTableColumnDataWithGap">
                <span>{parseDateStart.date}</span>
                <span className="Gray600Color">{parseDateStart.time}</span>
              </div>
            ) : (
              blankValue
            )}
            {!!parseDateEnd ? (
              <div className="FlexRow SportboomTableColumnDataWithGap">
                <span>{parseDateEnd.date}</span>
                <span className="Gray600Color">{parseDateEnd.time}</span>
              </div>
            ) : (
              blankValue
            )}
          </div>
        )
      }
    },
    [TableColumns.bet_type]: {
      title: () => {
        const isActive = !!tableFilter && !!tableFilter.sort && !!setTableFilter
        const isSort =
          tableFilter.sort &&
          !!tableFilter.sort.order &&
          tableFilter.sort.field === TableColumns.bet_type
            ? getColumnSort(tableFilter.sort.order)
            : undefined
        return isActive ? (
          <TableHeader
            isActiveField={
              tableFilter.sort.field === TableColumns.bet_type ||
              !!tableFilter.table[TableColumns.bet_type]?.length
            }
            isSort={isSort}
            className={checkIsFirstClass(TableColumns.bet_type)}
            title={tableConstants[TableColumns.bet_type].label}
          >
            <CheckListFilter
              list={filterLists[TableColumns.bet_type].filter}
              initialList={tableFilter.table[TableColumns.bet_type]}
              changeFilter={(newList: any[]) => {
                const isAll =
                  filterLists[TableColumns.bet_type].filter.length ===
                  newList.length
                setTableFilter({
                  ...tableFilter,
                  pagination: getPaginationDefault(
                    tableFilter.pagination.limit
                  ),
                  table: {
                    ...tableFilter.table,
                    [TableColumns.bet_type]: isAll ? undefined : newList
                  }
                })
              }}
            />
          </TableHeader>
        ) : (
          <div className="TableHeaderWithoutSpacing TableHeaderFirstTh">
            {tableConstants[TableColumns.bet_type].label}
          </div>
        )
      },
      fixed: checkIsFirstFix(TableColumns.bet_type),
      width: tableConstants[TableColumns.bet_type].width,
      dataIndex: TableColumns.bet_type,
      key: TableColumns.bet_type,
      render: (bet_type: TableDataBetType) => (
        <div
          className={`FlexRow ${checkIsFirstClass(
            TableColumns.bet_type
          )} SportboomTableColumnDataWithGap`}
        >
          {bet_type.isLive ? <LiveIcon /> : <div />}
          <span>
            {tableConstants[TableColumns.bet_type].types[bet_type.value]}
          </span>
          <span>{bet_type.count ? `(${bet_type.count})` : blankValue}</span>
        </div>
      )
    },
    [TableColumns.result]: FromToColumn({
      tableFilter,
      checkIsFirstClass,
      setTableFilter,
      checkIsFirstFix,
      fromToField: TableColumns.result
    }),
    [TableColumns.win]: FromToColumn({
      tableFilter,
      checkIsFirstClass,
      setTableFilter,
      checkIsFirstFix,
      fromToField: TableColumns.win
    }),
    [TableColumns.coefficient]: FromToColumn({
      tableFilter,
      checkIsFirstClass,
      setTableFilter,
      checkIsFirstFix,
      fromToField: TableColumns.coefficient
    }),
    [TableColumns.bet]: FromToColumn({
      tableFilter,
      checkIsFirstClass,
      setTableFilter,
      checkIsFirstFix,
      fromToField: TableColumns.bet
    }),
    [TableColumns.status]: {
      title: () =>
        CheckListFilterTitle({
          tableFilter,
          setTableFilter,
          checkIsFirstClass,
          checkField: TableColumns.status
        }),
      fixed: checkIsFirstFix(TableColumns.status),
      width: tableConstants[TableColumns.status].width,
      dataIndex: TableColumns.status,
      key: TableColumns.status,
      render: (status: TableDataStatus, record: TableData) => {
        const className = classByStatus(status)
        const isActive = selectedList.includes(record.bet_number)
        return (
          <div
            className={`${checkIsFirstClass(TableColumns.status)} ${className}${
              isActive ? `Active` : ``
            }`}
          >
            {tableConstants[TableColumns.status].text[status]}
          </div>
        )
      }
    },
    [TableColumns.guest]: {
      title: () => {
        const isSort =
          tableFilter.sort &&
          !!tableFilter.sort.order &&
          tableFilter.sort.field === TableColumns.guest
            ? getColumnSort(tableFilter.sort.order)
            : undefined
        return (
          <TableHeader
            isSort={isSort}
            isActiveField={!!tableFilter.table[TableColumns.guest]?.value}
            className={checkIsFirstClass(TableColumns.guest)}
            title={tableConstants[TableColumns.guest].label}
          >
            <SearchFilter
              value={tableFilter.table[TableColumns.guest]?.value}
              withEqual={false}
              placeholder={tableConstants[TableColumns.guest].placeholder}
              changeFilter={(newValue: any) =>
                setTableFilter({
                  ...tableFilter,
                  pagination: getPaginationDefault(
                    tableFilter.pagination.limit
                  ),
                  table: {
                    ...tableFilter.table,
                    [TableColumns.guest]: newValue
                  }
                })
              }
            />
          </TableHeader>
        )
      },
      fixed: checkIsFirstFix(TableColumns.guest),
      width: tableConstants[TableColumns.guest].width,
      dataIndex: TableColumns.guest,
      key: TableColumns.guest,
      render: (guest: string) => (
        <div className={checkIsFirstClass(TableColumns.guest)}>{guest}</div>
      )
    },
    [TableColumns.balance]: {
      title: () =>
        CheckListFilterTitle({
          tableFilter,
          setTableFilter,
          checkIsFirstClass,
          checkField: TableColumns.balance
        }),
      fixed: checkIsFirstFix(TableColumns.balance),
      width: tableConstants[TableColumns.balance].width,
      dataIndex: TableColumns.balance,
      key: TableColumns.balance,
      render: (balance: TableDataBalance) => (
        <span className={checkIsFirstClass(TableColumns.balance)}>
          {tableConstants[TableColumns.balance].text[balance]}
        </span>
      )
    },
    [TableColumns.freebet_id]: {
      title: () => (
        <div
          className={`TableHeaderWithoutSpacing ${checkIsFirstClass(
            TableColumns.freebet_id
          )}`}
        >
          {tableConstants[TableColumns.freebet_id].label}
        </div>
      ),
      fixed: checkIsFirstFix(TableColumns.freebet_id),
      width: tableConstants[TableColumns.freebet_id].width,
      dataIndex: TableColumns.freebet_id,
      key: TableColumns.freebet_id,
      render: (freebet_id: number) => (
        <span className={checkIsFirstClass(TableColumns.freebet_id)}>
          {freebet_id || blankValue}
        </span>
      )
    },
    [TableColumns.wallet_id]: {
      title: () => (
        <div
          className={`TableHeaderWithoutSpacing ${checkIsFirstClass(
            TableColumns.wallet_id
          )}`}
        >
          {tableConstants[TableColumns.wallet_id].label}
        </div>
      ),
      fixed: checkIsFirstFix(TableColumns.wallet_id),
      width: tableConstants[TableColumns.wallet_id].width,
      dataIndex: TableColumns.wallet_id,
      key: TableColumns.wallet_id,
      render: (wallet_id: number) => (
        <WalletIdCell
          id={wallet_id}
          onWalletIdClick={onWalletIdClick}
          additionalClassName={checkIsFirstClass(TableColumns.wallet_id)}
        />
      )
    },
    [TableColumns.hall]: {
      title: () => (
        <div
          className={`TableHeaderWithoutSpacing ${checkIsFirstClass(
            TableColumns.hall
          )}`}
        >
          {tableConstants[TableColumns.hall].label}
        </div>
      ),
      fixed: checkIsFirstFix(TableColumns.hall),
      width: tableConstants[TableColumns.hall].width,
      dataIndex: TableColumns.hall,
      key: TableColumns.hall,
      render: (hall: number) => (
        <span className={checkIsFirstClass(TableColumns.hall)}>{hall}</span>
      )
    }
  }

  if (key !== TableColumns.event_id) {
    return allColumnRenders[key]
  } else {
    return {}
  }
}

export const getColumns = ({
  tableConfig,
  selectedList,
  openDetailCurrent,
  tableFilter,
  setTableFilter,
  gameList,
  onWalletIdClick
}: GetColumnsMethodProps) => {
  let widthCount = 0
  const onlyVisible = tableConfig.filter(item => item.visible)
  const firstElem = onlyVisible[0]?.field
  const list = onlyVisible.map(item => {
    widthCount = widthCount + tableConstants[item.field]?.width
    return columnsOptions({
      selectedList,
      openDetailCurrent,
      key: item.field,
      tableFilter,
      setTableFilter,
      gameList,
      firstElem,
      onWalletIdClick
    })
  })
  return { widthCount, list }
}

const emptyColumnComponent = (field: TableColumns) => ({
  title: () => (
    <div className="TableHeaderWithoutSpacing TableHeaderFirstTh">
      {tableConstants[field].label}
    </div>
  ),
  width: tableConstants[field].width,
  dataIndex: field,
  key: field,
  render: () => emptyField(tableConstants[field].width)
})

const insideColumnsOptions = ({
  selectedList,
  key,
  firstElem
}: InsideColumnsOptionsMethodProps) => {
  const checkIsFirstClass = (column: TableColumns) => {
    if (firstElem === TableColumns.status) {
      return `SportboomTableFirstItemMargin`
    } else {
      return firstElem === column ? `SportboomTableFirstItem` : ``
    }
  }
  const allColumnRenders = {
    [TableColumns.bet_number]: emptyColumnComponent(TableColumns.bet_number),
    [TableColumns.sport_type]: {
      title: () => (
        <div className="TableHeaderWithoutSpacing">
          {tableConstants[TableColumns.sport_type].label}
        </div>
      ),
      width: tableConstants[TableColumns.sport_type].width + 10,
      dataIndex: TableColumns.sport_type,
      key: TableColumns.sport_type,
      render: (sport_type: SportTypeData[], record: { event_id: string }) => (
        <div
          className={`FlexColumn Gap4 ${checkIsFirstClass(
            TableColumns.sport_type
          )}`}
        >
          <span className="TextDefaultBold">{sport_type}</span>
          <span className="TextDefault">
            {tableConstants[TableColumns.event_id].inside_label(
              record.event_id
            )}
          </span>
        </div>
      )
    },
    [TableColumns.event_id]: emptyColumnComponent(TableColumns.event_id),
    [TableColumns.period]: emptyColumnComponent(TableColumns.period),
    [TableColumns.bet_type]: {
      title: () => (
        <div className="TableHeaderWithoutSpacing TableHeaderFirstTh">
          {tableConstants[TableColumns.bet_type].label}
        </div>
      ),
      width: tableConstants[TableColumns.bet_type].width,
      dataIndex: TableColumns.bet_type,
      key: TableColumns.bet_type,
      render: (bet_type: TableDataBetType) => (
        <span
          className={`TextDefault ${checkIsFirstClass(TableColumns.bet_type)}`}
        >
          {bet_type}
        </span>
      )
    },
    [TableColumns.result]: emptyColumnComponent(TableColumns.result),
    [TableColumns.win]: emptyColumnComponent(TableColumns.win),
    [TableColumns.coefficient]: {
      title: () => (
        <div className="TableHeaderWithoutSpacing TableHeaderFirstTh">
          {tableConstants[TableColumns.coefficient].label}
        </div>
      ),
      width: tableConstants[TableColumns.coefficient].width,
      dataIndex: TableColumns.coefficient,
      key: TableColumns.coefficient,
      render: (coefficient: number) => (
        <span
          className={`SportboomInsideCoefficientItem ${checkIsFirstClass(
            TableColumns.coefficient
          )}`}
        >
          {coefficient}
        </span>
      )
    },
    [TableColumns.bet]: emptyColumnComponent(TableColumns.bet),
    [TableColumns.status]: {
      title: () => (
        <div className="TableHeaderWithoutSpacing TableHeaderFirstTh">
          {tableConstants[TableColumns.status].label}
        </div>
      ),
      width: tableConstants[TableColumns.status].width,
      dataIndex: TableColumns.status,
      key: TableColumns.status,
      render: (status: TableDataStatus | null, record: TableData) => {
        const className = !!status ? classByStatus(status) : ``
        const isActive = selectedList.includes(record.bet_number)
        return (
          <div
            className={`SportboomInsideCoefficientItem ${className}${
              isActive ? `Active` : ``
            } ${checkIsFirstClass(TableColumns.status)}`}
          >
            {!!status
              ? tableConstants[TableColumns.status].text[status]
              : blankValue}
          </div>
        )
      }
    },
    [TableColumns.guest]: emptyColumnComponent(TableColumns.guest),
    [TableColumns.balance]: emptyColumnComponent(TableColumns.balance),
    [TableColumns.freebet_id]: emptyColumnComponent(TableColumns.freebet_id),
    [TableColumns.wallet_id]: emptyColumnComponent(TableColumns.wallet_id),
    [TableColumns.hall]: emptyColumnComponent(TableColumns.hall)
  }

  return allColumnRenders[key]
}

export const getInsideColumns = ({
  tableConfig,
  selectedList
}: GetInsideColumnsMethodProps) => {
  let widthCount = 0
  const onlyVisible = tableConfig.filter(item => item.visible)
  const firstElem = onlyVisible[0]?.field
  const list = onlyVisible.map(item => {
    widthCount = widthCount + tableConstants[item.field]?.width
    return insideColumnsOptions({
      selectedList,
      key: item.field,
      firstElem
    })
  })
  return { widthCount, list }
}
